import {getCanonicalURL} from "~/init/canonicalURL";

export function setHeadTags(language: string, land:string, shopname: string, shopdescription: string, image: string, produktInfo: {
    preis: string,
    currencyCode: string,
    shopUUID: string
} | null = null, url:string) {
    let title = "owayo shop " + shopname;
    let tags = [
        {name: "Content-Language", content: language},
        {name: "title", content: title},
        {name: "description", content: shopdescription},
        {name: "robots", content: "index, follow, noodp"},
        {name: "revisit-after", content: "7 days"},
        {name: 'page-topic', content: 'Sport'},
        {name: 'page-type', content: 'Produktinfo'},
        {name: 'og:title', content: title},
        {name: 'og:description', content: shopdescription},
        {name: 'og:image', content: image},
        {name: 'og:url', content: url},

        {name: 'twitter:card', content: 'summary_large_image'},
        {name: 'twitter:title', content: title},
        {name: 'twitter:description', content: shopdescription},
        {name: 'twitter:image', content: image},
        {name: 'twitter:site', content: url}
    ] as Array<{name:string, content:string}|{property:string, content:string}>;
    if (produktInfo === null) {
        tags.push({name: 'og:type', content: "website"});
    } else {
        tags = tags.concat([
            {property: 'og:type', content: 'product'},
            {property: 'product:brand', content: 'owayo'},
            {property: 'product:availability', content: 'in stock'},
            {property: 'product:condition', content: 'new'},
            {property: 'product:price:amount', content: produktInfo.preis},
            {property: 'product:price:currency', content: produktInfo.currencyCode},
            {property: 'product:retailer_item_id', content: produktInfo.shopUUID}
        ]);
    }
    let canonicalURL = getCanonicalURL(language, land, shopname);
    if(produktInfo?.shopUUID) {
        canonicalURL +="/"+produktInfo.shopUUID
    }
    useHead({
            title: title,
            meta: tags,
            link: [{rel:"canonical", href: canonicalURL}]
        }
    )
}
